<template>
  <a-modal
    :visible="modalVisible"
    title="现金收费"
    :destroyOnClose="true"
    :width="520" @cancel="modalVisible = false, $emit('update:visible', false)"
  >
    <a-radio-group name="radioGroup" v-model="payType" @change="payTypeChange">
      <a-radio :value="2">支付宝</a-radio>
      <a-radio :value="1">微信</a-radio>
    </a-radio-group>
    <p style="text-align: center; margin-top: 20px;">
      <img :src="qrcodeImg" v-if="qrcodeImg"
           style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
    </p>
    <template slot="footer">
      <a-button @click="modalVisible = false, $emit('update:visible', false)"> 关闭 </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'PayQrcode',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    getQrcodeFun: {
      type: Function,
      required: true
    }
  },
  watch: {
    visible: function(val) {
      if (val) {
        this.getQrcodeFun(this.params).then(v => {
          this.modalVisible = true
          this.qrcodeImg = v
        })
      }
    }
  },
  data() {
    return {
      payType: 2,
      qrcodeImg: '',
      modalVisible: false
    }
  },
  created() {
    this.getQrcodeFun(this.params).then(v => {
      this.modalVisible = true
      this.qrcodeImg = v
    })
  },
  methods: {
    payTypeChange() {
      this.getQrcodeFun({ ...this.params, deal_type: this.payType }).then(v => {
        this.qrcodeImg = v
      })
    }
  }
}
</script>
